import React, { useState } from "react"
import styled from "styled-components"
import ActionButton from "./ActionButton"
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core"

const Container = styled("div")`
  .inner-container {
    width: 100%;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
  }

  .contact-form-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: #eeeeee;
    padding: 1rem;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    .contact-icon {
      flex: 0 0 auto;
      margin-right: 2rem;
    }

    .header-text {
      flex: 1;

      h3 {
        margin: 0 0 0.5rem;
      }
      p {
        margin: 0;
        font-size: 0.85rem;
        color: #59626a;
      }
    }
  }

  .contact-form-content {
    .row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 1rem;

      .form-field {
        flex: 1;
        margin-right: 1rem;
      }

      .form-field:last-child {
        margin-right: 0;
      }
    }

    .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSelect.MuiOutlinedInput-inputSelect.MuiInputBase-inputMarginDense.MuiOutlinedInput-inputMarginDense {
      white-space: normal;
    }
  }

  .contact-form-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
  }

  .status-messages {
    position: relative;
  }
`

function isEmailValid(email) {
  if (!email) return true
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const result = re.test(String(email).toLowerCase())
  return result
}

const NoModalContactForm = ({
  onClose,
  title,
  description,
  inquiryOptions,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [success, setSuccess] = useState(undefined)
  const [formData, setForm] = useState({
    name: "",
    email: "",
    inquiryType: inquiryOptions ? inquiryOptions[0] : "",
    message: "",
  })

  const { name, email, inquiryType, message } = formData

  const validEmail = isEmailValid(email)

  function submitForm(e) {
    setIsSubmitting(true)
    e.preventDefault()
    const formData = new FormData(e.target)
    console.log("submitting form data")
    fetch(`/`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        console.log("success")
        setIsSubmitting(false)
        setSuccess(true)
        setForm({
          name: "",
          email: "",
          inquiryType: "",
          message: "",
        })
      })
      .catch(err => {
        console.log("error", err)
        setIsSubmitting(false)
        setSuccess(false)
      })
  }

  function handleClose() {
    setSuccess(undefined)
    onClose()
  }
  return (
    <Container>
      <div className="inner-container">
        {success !== true && success !== false && (
          <form
            className="contact-form"
            onSubmit={submitForm}
            name="contact"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
          >
            <div className="contact-form-content">
              <div className="row">
                <TextField
                  id="full-name"
                  name="name"
                  className="form-field"
                  label="Your full name"
                  variant="outlined"
                  margin="dense"
                  value={name}
                  onChange={e =>
                    setForm({ ...formData, ...{ name: e.currentTarget.value } })
                  }
                />
                <TextField
                  id="email"
                  name="email"
                  className="form-field"
                  label="Email Address"
                  variant="outlined"
                  margin="dense"
                  error={!validEmail}
                  value={email}
                  onChange={e =>
                    setForm({
                      ...formData,
                      ...{ email: e.currentTarget.value },
                    })
                  }
                />
              </div>
              <div className="row">
                <div className="form-field">
                  <FormControl style={{ display: "flex" }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{
                        transform: "translate(14px, -6px) scale(0.75)",
                        background: "white",
                        display: "inline-block",
                        zIndex: 1,
                      }}
                    >
                      Type of inquiry
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={inquiryType}
                      variant="outlined"
                      margin="dense"
                      labelWidth={100}
                      onChange={e => {
                        setForm({
                          ...formData,
                          ...{ inquiryType: e.target.value },
                        })
                      }}
                    >
                      {inquiryOptions &&
                        inquiryOptions.map(o => (
                          <MenuItem value={o}>{o.label}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="row">
                <TextField
                  id="message"
                  name="message"
                  className="form-field"
                  label="Your message"
                  variant="outlined"
                  margin="dense"
                  multiline={true}
                  rows="4"
                  value={message}
                  onChange={e =>
                    setForm({
                      ...formData,
                      ...{ message: e.currentTarget.value },
                    })
                  }
                />
              </div>
            </div>
            <div className="contact-form-footer">
              <Button
                onClick={onClose}
                style={{
                  textTransform: "capitalize",
                  marginRight: "1rem",
                  color: "#000000",
                  fontFamily: "montserrat",
                }}
              >
                Cancel
              </Button>
              <ActionButton
                disabled={
                  isSubmitting ||
                  success !== undefined ||
                  !name ||
                  !inquiryType ||
                  !email ||
                  !validEmail ||
                  !message
                }
              >
                Send Message
              </ActionButton>
            </div>
            <input
              type="hidden"
              name="inquiryType"
              value={inquiryType ? inquiryType.label : ""}
            />
            <input
              type="hidden"
              name="recipient"
              value={inquiryType ? inquiryType.value : ""}
            />
            <input type="hidden" name="form-name" value="contact" />
          </form>
        )}
        {success === true && (
          <>
            <div style={{ textAlign: "center", padding: "2rem 0 0" }}>
              Message sent. We will reply shortly.
            </div>
            <div className="contact-form-footer">
              <ActionButton onClick={handleClose}>Close</ActionButton>
            </div>
          </>
        )}
        {success === false && (
          <>
            <div style={{ textAlign: "center", padding: "2rem 0 0" }}>
              Looks like we had some trouble sending your request through.
              Please try again, or just end an email directly to{" "}
              <a href="mailto:services@carlyriangroup.com">
                services@carlyriangroup.com
              </a>
              . Thanks, and sorry for the inconvenience.
            </div>
            <div className="contact-form-footer">
              <ActionButton onClick={() => setSuccess(undefined)}>
                Try again
              </ActionButton>
            </div>
          </>
        )}
      </div>
    </Container>
  )
}

export default NoModalContactForm
