import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import { ContentSection } from "../components/ContentSection"
import TopSubNav from "../components/TopSubNav"
import NoModalContactForm from "../components/NoModalContactForm"
import { RichText } from "prismic-reactjs"

export const query = graphql`
  {
    prismic {
      allContacts {
        edges {
          node {
            seo_page_title
            seo_page_description
            seo_h1
            intro_text
            page_title
            address
            social_links {
              social_image
              social_link {
                _linkType
                ... on PRISMIC__ExternalLink {
                  _linkType
                  url
                }
              }
            }
          }
        }
      }
      allContact_forms {
        edges {
          node {
            title
            description
            inquiry_types {
              inquiry_type_label
              inquiry_type_value
            }
          }
        }
      }
    }
  }
`

const ContactCard = styled("div")`
  max-width: 900px;
  margin: 0 auto;
  background: white;
  box-shadow: 0 1px 40px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  display: grid;
  grid-template-columns: minmax(300px, 600px) auto;
  grid-template-rows: auto;
  z-index: 10;

  @media screen and (max-width: 720px) {
    grid-template-columns: 1;
    grid-template-rows: auto;
  }

  h1,
  h3 {
    font-size: 1.3rem;
    margin: 1rem 0;
  }

  .contact-form-container {
    padding: 1rem;
    height: 100%;

    h1,
    h3 {
      padding: 0 1rem;
    }

    @media screen and (max-width: 720px) {
      grid-column: 1;
      grid-row: 1;
    }
  }

  form.contact-form {
    padding: 0;
  }

  .contact-details {
    border-left: solid 1px rgba(0, 0, 0, 0.2);
    height: 100%;
    padding: 1rem;

    @media screen and (max-width: 600px) {
      border-top: solid 1px rgba(0, 0, 0, 0.2);
      border-left: none;
      grid-column: 1;
      grid-row: 2;
    }
  }

  .address {
    p {
      font-size: 0.8rem;
    }
  }

  .social-links {
    a {
      margin-right: 5px;
    }
  }
`

const ContactContentSection = styled("div")`
  position: relative;
  background-image: url("https://images.prismic.io/crgwebsite/16f3c7c1-9385-48cb-a8e7-27f233b657a2_grpmap-229yongestreet-darker.png?auto=compress,format");
  background-repeat: no-repeat;
  background-position: top 200px center;
  background-size: cover;
  padding-bottom: 200px;

  @media screen and (max-width: 600px) {
    background-image: none;
    padding-bottom: 0;
  }
`

const renderComponent = data => {
  const doc = data.prismic.allContacts.edges.slice(0, 1).pop()
  if (!doc) return null

  const seoTitle =
    (doc.node &&
      doc.node.seo_page_title &&
      doc.node.seo_page_title[0] &&
      doc.node.seo_page_title[0].text) ||
    (doc.node &&
      doc.node.page_title &&
      doc.node.page_title[0] &&
      doc.node.page_title[0].text) ||
    ""
  const seoDescription =
    (doc.node &&
      doc.node.seo_page_description &&
      doc.node.seo_page_description[0] &&
      doc.node.seo_page_description[0].text) ||
    ""

  const formDoc = data.prismic.allContact_forms.edges.slice(0, 1).pop()
  if (!formDoc) return null

  const inquiryOptions = formDoc.node.inquiry_types.map(it => ({
    label: it.inquiry_type_label[0].text,
    value: it.inquiry_type_value[0].text,
  }))

  function handleOtherClick(e) {
    e.stopPropagation()
  }

  function handleMapClick() {
    if (window) {
      window.location = "https://goo.gl/maps/hG4eTUqzSwHPFJeM7"
    }
  }
  return (
    <Layout>
      <SEO title={seoTitle} seoDescription={seoDescription} />
      <TopSubNav title={doc.node.page_title[0].text} />
      <ContentSection>
        <div className="inner-container">
          <h2 style={{ fontSize: "2rem", textAlign: "center" }}>
            {doc.node.intro_text[0].text}
          </h2>
        </div>
      </ContentSection>

      <ContactContentSection onClick={handleMapClick}>
        <ContactCard onClick={handleOtherClick}>
          <div className="contact-form-container">
            {doc.node.seo_h1 && RichText.render(doc.node.seo_h1)}
            <NoModalContactForm inquiryOptions={inquiryOptions} />
          </div>
          <div className="contact-details">
            <h3>Our Address</h3>
            <div className="address">{RichText.render(doc.node.address)}</div>
            <h3>Our Social</h3>
            <div className="social-links">
              {doc.node.social_links.map(l => (
                <a
                  href={l.social_link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={l.social_image.url} alt={l.social_image.alt} />
                </a>
              ))}
            </div>
          </div>
        </ContactCard>
      </ContactContentSection>
    </Layout>
  )
}

export default () => {
  return (
    <>
      <StaticQuery query={query} render={withPreview(renderComponent, query)} />
    </>
  )
}
